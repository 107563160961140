export function useSessionAnswers(info: {
  sessionId: string;
  pageSlug: string;
  flowId: number | string;
}) {
  const storeKey = 'answers';
  const { sessionId, pageSlug, flowId } = info || {};

  function getSavedAnswerSessionKey() {
    return globalThis?.btoa(`${sessionId}:${pageSlug}:${flowId}`);
  }

  function getSavedAnswers(): Record<string, any> {
    return JSON.parse(globalThis?.localStorage.getItem(storeKey) || '{}');
  }

  function getSavedSessionAnswers(): Record<string, any> {
    return (getSavedAnswers()?.[getSavedAnswerSessionKey()]) || {};
  }

  function clearSavedAnswers() {
    globalThis?.localStorage.setItem(storeKey, '{}');
  }

  function saveSessionAnswers(sessionAnswers: any) {
    const sessionKey = getSavedAnswerSessionKey();
    const savedAnswsers = isValidSession() ? getSavedAnswers() : {};

    // Save question answer in session answers
    savedAnswsers[sessionKey] = sessionAnswers;

    // Save session answers to localStorage
    globalThis?.localStorage.setItem(storeKey, JSON.stringify(savedAnswsers));
  }

  function saveAnswerToTemp(payload: {
    questionId: number;
    answer: { options: number[]; input: string; }
  }) {
    const sessionAnswers = getSavedSessionAnswers();

    // Set answer by question id
    sessionAnswers[payload.questionId] = JSON.stringify(payload.answer);

    return saveSessionAnswers(sessionAnswers);
  }

  function isValidSession() {
    const sessionKey = getSavedAnswerSessionKey();
    const answers = getSavedAnswers();
    return !!answers?.[sessionKey];
  }

  return {
    sessionInfo: info,
    getSavedAnswers,
    getSavedAnswerSessionKey,
    getSavedSessionAnswers,
    saveSessionAnswers,
    saveAnswerToTemp,
    clearSavedAnswers
  }
}
